import { createContext, useContext, useState } from "react";
import {
  resetLocalStorageWithExpiry,
  setLocalStorageWithExpiry,
  updateLocalStorageWithExpiry,
} from "../utils";

const Context = createContext();

export const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState();

  const updateUser = (value) => {
    setUser((prev) => ({ ...prev, ...value }));
    updateLocalStorageWithExpiry("user", value);
  };

  const resetUser = (value) => {
    setUser(value);
    resetLocalStorageWithExpiry("user", value);
  };

  const loginUser = (value) => {
    setLocalStorageWithExpiry("user", value, 1400 * 60 * 1000);
    setUser(value);
  };

  return (
    <Context.Provider
      value={{ user, setUser, updateUser, resetUser, loginUser }}
    >
      {children}
    </Context.Provider>
  );
};

export default function useUserContext() {
  return useContext(Context);
}
