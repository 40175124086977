import axios from "axios";

export const cleanUserData = (apires) => {
  let isSpecialUser = apires?.role === "specialUser";
  let isMembershipActive = isSpecialUser
    ? apires?.approvalStatus === "approved"
    : apires?.subscriptionStatus?.isActive;
  let membershipType =
    apires?.subscriptionStatus?.currentSubscribedProduct?.metadata
      ?.membershipType || apires?.membershipType;
  return { ...apires, isSpecialUser, isMembershipActive, membershipType };
};

export const getProfileInfo = async ({ id }) => {
  try {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}profile/${id}`,
    };

    const res = await axios(config);
    const data = cleanUserData(res?.data?.data);
    return { ...res?.data, data };
  } catch (error) {
    throw error;
  }
};

export const updateProfileInfo = async ({ id, data }) => {
  try {
    let config = {
      method: "PATCH",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}profile/${id}`,
      data: {
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: data.phoneNumber,
      },
    };

    const res = await axios(config);
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const updatePassword = async ({ id, data }) => {
  try {
    let config = {
      method: "PATCH",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}profile/${id}`,
      data: {
        currentPassword: data.currentPassword,
        password: data.password,
      },
    };

    const res = await axios(config);
    return res?.data;
  } catch (error) {
    throw error;
  }
};

export const updateProfilePic = async ({ id, profilePicture }) => {
  try {
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_URL}profile/picture/${id}`,

      data: { profilePicture },
    };

    const res = await axios(config);
    return res.data;
  } catch (error) {
    throw error;
  }
};
