import { createContext, useContext, useState } from "react";

const Context = createContext();

export function AppNotificationsContextProvider({ children }) {
  const [appNotification, setAppNotification] = useState([]);

  const clearAll = () => {
    setAppNotification([]);
  };

  return (
    <Context.Provider value={{ appNotification, setAppNotification, clearAll }}>
      {children}
    </Context.Provider>
  );
}

export default function useAppNotifications() {
  return useContext(Context);
}
