export const formatLikes = (likes) => {
  if (likes >= 1000000) {
    return (likes / 1000000).toFixed(1) + "M";
  } else if (likes >= 1000) {
    return (likes / 1000).toFixed(1) + "k";
  } else {
    return likes?.toString();
  }
};
export const formatSecondsToHours = (seconds) => {
  if (!seconds) return "-";
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  // Format the time as `hh:mm:ss` where hours are optional if 0
  return `${hours > 0 ? `${hours}:` : ""}${String(minutes).padStart(
    2,
    "0"
  )}:${String(secs).padStart(2, "0")}`;
};

export function setLocalStorageWithExpiry(key, value, ttl) {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getLocalStorageWithExpiry(key) {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  let item;
  try {
    item = JSON.parse(itemStr);
  } catch (error) {
    return itemStr;
  }
  const now = new Date();
  if (!item.expiry || now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

export function updateLocalStorageWithExpiry(key, value) {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const oldItem = JSON.parse(itemStr);

  const item = {
    value: oldItem?.value,
    expiry: oldItem?.expiry,
  };

  item.value = { ...item.value, ...value };
  localStorage.setItem(key, JSON.stringify(item));
}

export function resetLocalStorageWithExpiry(key, value) {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const oldItem = JSON.parse(itemStr);

  const item = {
    value,
    expiry: oldItem?.expiry,
  };

  localStorage.setItem(key, JSON.stringify(item));
}
